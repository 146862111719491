.react-datepicker {
    @apply p-3 bg-grey-1 border border-grey-8 rounded shadow-[0_2px_8px_rgba(0,0,0,0.2),0_1px_3px_rgba(0,0,0,0.15),0_0_2px_rgba(0,0,0,0.25)];

    &__header {
        @apply p-0 bg-grey-1 border-b-0;
    }

    &__month {
        @apply p-0 m-0;
    }

    &__day-names {
        @apply m-0;
    }

    &__day-name, &__day {
        @apply w-6 h-6 !leading-[24px];
    }

    &__day-name {
        @apply text-grey-9 text-10 font-bold w-8 h-6 m-0;
    }

    &__day {
        @apply text-grey-11 m-1 font-normal text-12 rounded-full;

        &:hover {
            @apply bg-grey-4 rounded-full;
        }

        &--selected, &--keyboard-selected, &--in-range, &--in-selecting-range {
            @apply text-grey-1 font-semibold bg-blue-10;

            &:hover {
                @apply bg-blue-10;
            }
        }

        &--outside-month {
            @apply text-grey-8;
        }

        &--in-selecting-range:not(&--in-range) {
            @apply text-grey-1 bg-blue-6;
        }
    }
}
